<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-09-15 14:17:53
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-27 09:05:30
-->
<template>
    <div class="distributeList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">共享圈列表</div>
        </div>
        <div class="case-wrap tabs-wrap">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="产品实景" name="first">
                    <productsAr ref="productsAr"></productsAr>
                </el-tab-pane>
                <el-tab-pane label="品牌主题" name="second">
                    <brandTheme ref="brandTheme"></brandTheme>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import brandTheme from './components/brandTheme'
import productsAr from './components/productsAr'
export default {
    components:{
        brandTheme,
        productsAr,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'distributeList',
            activeName: 'first',
        }
    },
    mounted () {

    },
    methods: {

    },
}
</script>

<style lang='scss'>
.distributeList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .tabs-wrap{
        background: #fff;
        margin-top: 20px;
        border-radius: 6px;
        .el-tabs__header{
            padding-top: 20px;
            margin: 0 20px;
        }
    }
}
</style>
