<!--
 * @Descripttion: 产品实景
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-09-15 14:17:53
 * @LastEditors: SilentStorm 1668645906@qq.com
 * @LastEditTime: 2022-11-10 09:50:23
-->
<template>
    <div class="productsAr">
        <div class="case-wrap">
            <div class="screen-wrap">
                <div class="sw-left">
                    <div class="search-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="sw-right">
                    <el-button class="btns" @click="isAddScenarios = true" type="primary">添加</el-button>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%;margin-top:10px;" >
                    <el-table-column align="center" label="图片/视频" class-name="vm-wrap" width="150">
                        <template slot-scope="scope">
                            <div class="tag" v-if="scope.row.imgs_type === 1">图</div>
                            <div class="tag2" v-if="scope.row.imgs_type === 2">视</div>
                            <img class="table-image" :src="scope.row.imgs[0]" 
                            v-if="scope.row.imgs_type === 1"
                            alt="" 
                             />
                            <img class="table-image" :src="scope.row.imgs[0]" 
                            v-if="scope.row.imgs_type === 2"
                            alt="" 
                            />
                            <!-- <video class="table-image" v-if="scope.row.imgs_type === 2"
                                @click="clickImage(1,scope.row)"
                                :src="scope.row.imgs[0]">
                            </video> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="describe" label="内容" align="center" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="classify_ids_transform" label="分类" align="center" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="goods_ids_transform" label="关联产品" align="center" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="收藏" prop="collection" align="center"></el-table-column>
                    <el-table-column label="点赞" prop="praises" align="center"></el-table-column>
                    <el-table-column label="浏览" prop="views" align="center"></el-table-column>
                    <el-table-column prop="create_timex" label="创建时间" align="center" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" label="是否隐藏">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.is_hidden"
                                @change="changeLowerup(scope.row)"
                                active-color="#13ce66"
                                inactive-color="#eeeeee">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="100">
                        <template slot-scope="scope">
                            <!-- <el-button class="button-item" type="text" size="small" @click="handeClickInfo(scope.row)">详情</el-button>
                            <i class="el-icon-minus"></i> -->
                            <el-button class="button-item" type="text" size="small" @click="handeClickDel(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :before-close="handleClose" 
            :close-on-click-modal="false"
            :visible.sync="isAddScenarios" 
            custom-class="dialog-wrap">
            <div class="title">添加产品实景内容</div>
            <div class="content-wrap">
                <el-form :model="shareForm" :rules="rulesShare" ref="shareForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="选择分类" prop="classify_ids">
                        <el-select clearable v-model="shareForm.classify_ids" multiple placeholder="请选择分类">
                            <el-option
                                v-for="item in classifyOptions"
                                :key="item.classify_id"
                                :label="item.name"
                                :value="item.classify_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关联产品" prop="goods_ids">
                        <el-select filterable clearable v-model="shareForm.goods_ids" :multiple-limit="5" multiple placeholder="请选择关联产品">
                            <el-option
                                v-for="item in goodsOptions"
                                :key="item.id"
                                :label="item.model"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="内容描述" prop="describe">
                        <el-input type="textarea" :rows="2"
                            placeholder="描述一下您发布的内容吧" v-model="shareForm.describe">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="发布类型" prop="imgsType">
                        <el-radio-group v-model="imgsType">
                            <el-radio :label="1">图片</el-radio>
                            <el-radio :label="2">视频</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="上传图片" prop="imgs" v-if="imgsType == 1">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="8" :limit="10" :isShopTip="false" multiple
                                :btnDisplay="shareForm.imgs.length<10" listType="picture-card"
                                v-model="shareForm.imgs">
                            </aliyun-upload>
                            <div v-if="shareForm.imgs.length>0" class="fileList-box">
                                <div class="box-list" v-for="(img,index) in shareForm.imgs" :key="index">
                                    <div @click="delImageList" class="delFile">x</div>
                                    <el-image fit="contain" class="pic"
                                        :preview-src-list="shareForm.imgs" :src="img"/>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="上传视频" prop="imgs" v-if="imgsType == 2">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="50" :limit="1" :isShopTip="false" accept="video/*"
                                :btnDisplay="shareForm.imgs.length<1" listType="picture-card"
                                v-model="shareForm.imgs">
                            </aliyun-upload>
                            <div v-if="shareForm.imgs.length>0" class="fileList-box">
                                <div @click="delImageList" class="delFile">x</div>
                                <video class="pic" width="300" controls :src="shareForm.imgs[0]"></video>
                            </div>
                        </div>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button :loading="btnLoading" type="primary" @click="submitForm">立即创建</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <!-- 列表详情弹窗 -->
        <div class="dialog-image-products-info">
            <el-dialog :visible.sync="isShowDialogInfo" 
                width="460px"
                :close-on-click-modal="false">
                <div class="header-wrap">
                    <img class="header-image" src="../../../assets/images/header.png" />
                </div>
                <div class="info-wrap" v-loading="loading">
                <div class="swiper-wrap">
                    <el-carousel trigger="click" height="250px" indicator-position="none" v-if="listInfo.type == 1">
                        <el-carousel-item v-for="(item,index) in listInfo.imageList" :key="index">
                            <div class="image-wrap" @click="clickImageInfo(listInfo)">
                                <img class="image-sw" :src="item" />
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="videos-wrap" v-if="listInfo.type == 2" @click="clickVideoPlay()">
                        <video ref="vueMiniVideoPlayer"  class="videos" 
                            :src="listInfo.imageList[0]">
                        </video>
                        <i class="icon-w el-icon-video-play"></i>
                    </div>
                </div>
                <div class="content-wrap">
                    <div class="cw-title-item">
                        <div class="cw-title-header">
                            <el-image class="iamge-title" :src="listInfo.user.head"></el-image>
                            <div class="title-wrap">
                                <div class="title">{{listInfo.user.username}}</div>
                                <div class="flan-date">
                                    <div class="span">{{listInfo.user.mobile}}</div>
                                    <div class="cw-title-date">发布：{{listInfo.releaseTime}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cw-title-tags">
                        <div v-for="(item,index) in listInfo.classifyList" :key="index">
                            <div class="tag">{{item}}</div>
                        </div>
                    </div>
                    <div class="cw-title-content">
                        {{listInfo.describe}}
                    </div>
                </div>
                </div>
                <div class="foot-wrap">
                    <img class="foot-image" src="../../../assets/images/foot.png" />
                </div>
            </el-dialog>
        </div>
        <!-- 点击图片多图轮播显示 -->
        <div class="dialog-imageVideo-list">
            <!-- 图片 -->
            <div v-if="isShowImages">
                <el-image-viewer style="z-index: 99999"
                :on-close="closeViewer" 
                :url-list="imageList" />
            </div>
            <el-dialog
                :visible.sync="isShowVideos"
                :close-on-click-modal="false"
                :before-close="handleCloseImage"
                width="460px"
                center>
                <video ref="vueMiniVideoPlayer" class="video-wrap"
                    controls  autoplay loop 
                    :src="videoList[0]">
                </video>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components:{
        ElImageViewer,
        AliyunUpload,
    },
    data () {
        return {
            isAddScenarios: false,
            loading: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            keyword: '',
            shareType: 1,
            imgsType: 1,
            shareForm: {
                describe: '',
                classify_ids: [],
                goods_ids: [],
                // theme_ids: [],
                imgs: []
            },
            rulesShare: {
                describe: [
                    { required: true, message: '请输入内容描述', trigger: 'blur' },
                ],
                classify_ids: [
                    { required: true, message: '请选择分类', trigger: 'change' },
                ],
                goods_ids: [
                    { required: true, message: '请选择关联产品', trigger: 'change' },
                ],
                imgs: [
                    { required: true, message: '请上传图片/视频', trigger: 'blur' },
                ],
            },
            btnLoading: false,
            buiOptions: [],
            classifyOptions: [],
            goodsOptions: [],
            themeOptions: [],
            isShowDialogInfo: false,
            listInfo:{
                imageList:[],
                releaseTime: '',
                themeList: [],
                classifyList: [],
                describe: '',
                isVideo: 0,
                user:{},
                type: 0,
                typel: 1
            },
            imageList: [],
            videoList: [],
            isShowVideos: false,
            isShowImages: false,
        }
    },
    mounted () {
        this.getMyPublish();
        this.getProductLibrary();
        // this.getThemeConditions();
        this.getClassifyConditions();
    },
    watch:{
        imgsType(newVal,oldVal){
            this.shareForm.imgs = []
        },
    },
    methods: {
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false;
        },
        //关闭弹窗视频暂停
        handleCloseImage(){
            this.isShowVideos = false;
            this.$refs.vueMiniVideoPlayer.pause();//暂停
        },
        // 视频播放
        clickVideoPlay(){
            this.isShowVideos = true;
            this.$nextTick(()=>{
                this.$refs.vueMiniVideoPlayer.play();//播放
            })
        },
        clickImageInfo(item){
            this.isShowImages = true;
            this.imageList = item.imageList;
        },
        //点击详情
        handeClickInfo(row){
            this.isShowDialogInfo = true;
            this.loading = true;
            common.connect("/distributorpcv1/sharing_community/show",{id: row.id},(res)=>{
                // res.data.imgs = res.data.imgs.split(",");
                res.data.imgs = JSON.parse(res.data.imgs);
                this.$set(this.videoList,0,res.data.imgs)
                this.$nextTick(()=>{
                    this.listInfo.imageList = res.data.imgs;
                    this.listInfo.releaseTime = res.data.create_timex.substring(0,10);
                    // this.listInfo.themeList = res.data.theme_ids_transform;
                    this.listInfo.classifyList = res.data.classify_ids_transform;
                    this.listInfo.describe = res.data.describe;
                    this.listInfo.user = res.data.user;
                    this.listInfo.type = res.data.imgs_type;
                    this.listInfo.typel = res.data.type;
                })
                this.loading = false;
            });
        },
        // 创建
        submitForm(){
            this.$refs.shareForm.validate((valid) => {
          if (valid) {
            if (this.shareForm.imgs[0].indexOf('.mp4')!=-1) {
                    this.shareForm.imgs[0] = this.shareForm.imgs[0].split('.jpg')[0]
                }
            let params = {
                type: this.shareType,
                describe: this.shareForm.describe,
                imgs: JSON.stringify(this.shareForm.imgs),
                imgs_type: this.imgsType,
                classify_ids: JSON.stringify(this.shareForm.classify_ids),
                goods_ids: JSON.stringify(this.shareForm.goods_ids)
            }
            this.btnLoading = true;
            common.connect('/distributorpcv1/sharing_community/store',params,(res)=>{
                if(res.isSuccess == 1){
                    this.isAddScenarios = false;
                    this.btnLoading = false
                    this.$refs.shareForm.resetFields();
                    this.getMyPublish();
                }else{
                    this.$message.error(res.msg);
                    this.btnLoading = false
                }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
        // 是否隐藏
        changeLowerup(row){
            let params = {
                id: row.id,
                is_hidden: row.is_hidden?1:0
            }
            common.connect('/distributorpcv1/sharing_community/updateIsHidden',params,(res)=>{
                if(res.isSuccess == 1){
                    this.getMyPublish();
                    this.$message.success('操作成功！')
                }
            })
        },
        // 删除
        handeClickDel(row){
            this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/distributorpcv1/sharing_community/delete',{id: row.id},(res)=>{
                    if(res.isSuccess == 1){
                        this.getMyPublish();
                        this.$message.success('删除成功！')
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        delImageList(){
            this.shareForm.imgs.splice(0,1)
        },
        // 获取列表数据
        getMyPublish(){
            let params = {
                page: this.currentPage,
                keyword: this.keyword,
                type: 1
            }
            this.loading = true;
            common.connect('/distributorpcv1/sharing_community/myPublish',params,(res)=>{
                res.data.list.forEach(v => {
                    // v.imgs = v.imgs.split(",");
                    v.imgs = JSON.parse(v.imgs);
                    v.is_hidden = (v.is_hidden == 1);
                    v.classify_ids_transform = (v.classify_ids_transform).join(',')
                    v.goods_ids_transform = (v.goods_ids_transform).join(',')
                });
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 产品实景分类
        getClassifyConditions(){
            common.connect('/distributorpcv1/sharing_community/classifyConditions',{},(res)=>{
                this.classifyOptions = res.data
            })
        },
        // 产品列表
        getProductLibrary(){
            common.connect('/distributorpcv1/sharing_community/relationGoods',{},(res)=>{
                this.goodsOptions = res.data.list;
            })
        },
        // 关闭弹窗
        handleClose(){
            this.isAddScenarios = false;
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getMyPublish();
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getMyPublish();
        },
    },
}
</script>

<style lang='scss'>
.productsAr{
    background: #fff;
    .case-wrap{
        background: #fff;
        padding: 20px;
        border-radius: 6px;
    }
    .screen-wrap{
        display: flex;
        justify-content: space-between;
        .sw-left{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .screen-item{
                margin-right: 15px;
            }
            .search-item{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 5px;
                .el-input{
                    width: 220px;
                    margin-right: 10px;
                }
            }
        }
    }
    .table-wrap{
        .vm-wrap{
            position: relative;
            .tag{
                position: absolute;
                top: 0;
                left: 0;
                background: #72a5fe;
                color: #fff;
                font-size: 14px;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: first baseline;
                -webkit-clip-path: polygon(30px 0px, 0px 0px, 0px 30px, 0px 30px);
            }
            .tag2{
                position: absolute;
                top: 0;
                left: 0;
                background: #ffca7a;
                color: #fff;
                font-size: 14px;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: first baseline;
                -webkit-clip-path: polygon(30px 0px, 0px 0px, 0px 30px, 0px 30px);
            }
            .tag3{
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                color: red;
                font-size: 10px;
                width: 100%;
                line-height: 15px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .table-image{
            cursor: pointer;
            max-width: 50px;
            max-height: 40px;
        }
        .button-item{
            width: 25px;
        }
        .el-icon-minus{
            transform: rotate(90deg);
            color: #d6e3f9;
            line-height: 0;
        }
        .pagination-wrap{
            display: flex;
            justify-content: flex-end;
            padding-top: 10px;
        }
    }
    .dialog-wrap{
        width: 750px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 630px
            }
            .el-textarea{
                width: 630px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .fileList-box{
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 300px;
                    .box-list{
                        width: 100px;
                        height: auto;
                        position: relative;
                    }
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
      
    }
    .dialog-image-products-info{
        .el-dialog{
            margin: 0 !important;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            .el-dialog__header{
                padding: 0;
            }
            .el-dialog__body{
                padding: 0;
            }
        }
        .header-wrap{
            width: 100%;
            .header-image{
                width: 100%;
            }
        }
        .foot-wrap{
            width: 100%;
            border-top: 1px solid #eee;
            overflow: hidden;
            .foot-image{
                width: 100%;
            }
        }
        .info-wrap{
            width: 100%;
            height: calc(100vh - 220px);
            overflow-y: auto;
        }
        .swiper-wrap{
            height: 250px;
            .image-wrap{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                .image-sw{
                    height: 100% !important;
                    width: auto;
                }
            }
            .videos-wrap{
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #000;
                .videos{
                    height: 100% !important;
                    object-fit: fill;  
                }
                .icon-w{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    font-size: 50px;
                }
            }
        }
        .content-wrap{
            padding: 10px 20px;
            .cw-title-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0;
                .cw-title-header{
                    display: flex;
                    flex-flow: wrap;
                    align-items: center;
                    .iamge-title{
                        width: 60px;
                        height: 60px;
                        border-radius: 60px;
                        border: 1px solid #eee;
                    }
                    .title-wrap{
                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;
                        .title{
                            font-size: 18px;
                            color: #000;
                            font-weight: bold;
                        }
                        .flan-date{
                            width: 330px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 5px;
                            .cw-title-date{
                                font-size: 14px;
                                color: #999;
                            }
                            .span{
                                font-size: 16px;
                                color: #999;
                            }
                        }
                    }
                }
            }
            .cw-title-tags{
                display: flex;
                flex-wrap: wrap;
                .tag{
                    padding: 5px 15px;
                    background: #d6e3f9;
                    color: #3272e1;
                    font-size: 15px;
                    font-weight: bold;
                    margin-right: 10px;
                    border-radius: 4px;
                    margin-bottom: 10px;
                }
            }
            .cw-title-content{
                padding: 20px 0;
                line-height: 24px;
            }
        }
    }
    .dialog-imageVideo-list{
        .el-dialog{
            margin: 0;
            margin-top: 0vh !important;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            max-height: calc(100vh - 50px);
            display: flex;
            justify-content: center;
        }
        .video-wrap{
            width: 400px;
            max-height: calc(100vh - 50px);
            margin-right: 40px;
            margin-top: 20px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
